.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: #1976d2;
  letter-spacing: 1px;
}

.App-link {
  color: #7dc1f5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-bold {
  font-weight: 700;
}

.page-container {
  padding: 0 50px 0 50px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.w-100 {
  width: 100%;
}
