.questions-container {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* 100% of the viewport height */
}

.add-question-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #66aaed;
  border: 1px;
  border-radius: 10px;
  height: 200px;
  padding: 20px;
  margin-top: 10px;
}

.checkmark-column {
  display: flex;
  justify-content: center; 
  width: 100%;
}

select,
input,
textarea {
  width: 90%;
  border-radius: 5px;
  border: none;
  margin-left: 20px;
}

.question-input {
  height: 50px;
}

.grid-item-centered {
  display: flex;
  align-items: center; /* Aligns items vertically */
  justify-content: flex-start; /* Aligns items to the start horizontally, adjust as needed */
}

.edit-cell {
  display: flex;
  flex-direction: column;
}
